import { Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { web_max_width } from '@common/styles';

export const FixedWrapper = styled.div`
  background: ${Static_White};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: ${web_max_width}px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 10;
  border-radius: 16px 16px 0 0;
`;
