import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconMypageSolid32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={2}
        d='M16 7A3.5 3.5 0 1 0 16 14 3.5 3.5 0 1 0 16 7z'
      />
      <path
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='square'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m24.644 25-2.165-7H9.644l-2.269 7h17.269Z'
      />
    </Svg>
  );
};
export default SvgIconMypageSolid32;
