import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconHeartRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        stroke='currentColor'
        strokeWidth={1.5}
        d='M20.546 7C23.558 7 26 9.519 26 12.626c0 1.406-.73 2.813-.73 2.813C23.246 19.93 16 25 16 25s-7.246-5.07-9.27-9.561c0 0-.73-1.407-.73-2.813C6 9.519 8.442 7 11.454 7 13.352 7 15.023 8 16 9.515 16.977 7.999 18.648 7 20.546 7Z'
      />
    </Svg>
  );
};
export default SvgIconHeartRegular32;
