import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  IconHomeRegular32,
  IconHomeSolid32,
  IconMenuBold32,
  IconMenuRegular32,
  IconMypageRegular32,
  IconMypageSolid32,
  IconHeartRegular32,
  IconHeartSolid32,
} from '@croquiscom/zds-core';
import { useGNBConfig } from '../context/ConfigProvider';

interface TabItemOption {
  title: string;
  ActvieIcon: ({ color }: JSX.IntrinsicElements['svg']) => JSX.Element;
  DeactiveIcon: ({ color }: JSX.IntrinsicElements['svg']) => JSX.Element;
  selected: boolean;
  href: string;
}

interface BottomTabBarService {
  home: TabItemOption;
  categories: TabItemOption;
  my_page: TabItemOption;
  picks: TabItemOption;
}

export function useBottomTabBarService(): BottomTabBarService {
  const { t } = useTranslation('common');
  const { path } = useGNBConfig();
  const router = useRouter();
  const route = router?.route;
  const pathname = router?.pathname;

  const home: TabItemOption = {
    title: t('홈'),
    ActvieIcon: IconHomeSolid32,
    DeactiveIcon: IconHomeRegular32,
    selected: isHomeSelected(),
    href: path.home,
  };

  const categories: TabItemOption = {
    title: '카테고리',
    ActvieIcon: IconMenuBold32,
    DeactiveIcon: IconMenuRegular32,
    selected: isCategoriesSelected(),
    href: path.categories.root,
  };

  const picks: TabItemOption = {
    title: '찜',
    ActvieIcon: IconHeartSolid32,
    DeactiveIcon: IconHeartRegular32,
    selected: isPicksSelected(),
    href: path.picks.root,
  };

  const my_page: TabItemOption = {
    title: t('마이페이지'),
    ActvieIcon: IconMypageSolid32,
    DeactiveIcon: IconMypageRegular32,
    selected: isMyPageSelected(pathname),
    href: path.my_page.root,
  };

  return { home, categories, my_page, picks };

  function isHomeSelected() {
    return route === path.home || route === path.root;
  }

  function isCategoriesSelected() {
    return route.includes(path.categories.root);
  }

  function isPicksSelected() {
    return route.includes(path.picks.root);
  }

  function isMyPageSelected(pathname?: string) {
    const match_paths = [path.orders, path.auth.root, path.my_page.root];
    return match_paths.some((path) => pathname?.match(path));
  }
}
