import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconHomeSolid32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9 5h14a4 4 0 0 1 4 4v14a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4Zm3.5 7h4.382l-5 10H19.5v-2h-4.382l5-10H12.5v2Z'
        clipRule='evenodd'
      />
    </Svg>
  );
};
export default SvgIconHomeSolid32;
