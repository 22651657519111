import { MouseEvent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Gray_Light_400, Gray_Light_900 } from '@croquiscom/zds';
import Link from 'next/link';
import { Typography } from '@common/styles';

interface BottomBarItemProps {
  title: ReactNode;
  selected: boolean;
  ActvieIcon: ({ color }: JSX.IntrinsicElements['svg']) => JSX.Element;
  DeactiveIcon: ({ color }: JSX.IntrinsicElements['svg']) => JSX.Element;
  href: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

export function BottomBarItem({ title, selected, ActvieIcon, DeactiveIcon, href, onClick }: BottomBarItemProps) {
  const color_stauts = colorStatus(selected);

  return (
    <LinkWrapper href={href}>
      <BarItemButton href={href} onClick={onClick}>
        {selected ? (
          <ActvieIcon color={color_stauts} width={28} height={28} />
        ) : (
          <DeactiveIcon color={color_stauts} width={28} height={28} />
        )}
        <BarItemTitle className={Typography.CAPTION_11_MEDIUM} color={color_stauts}>
          {title}
        </BarItemTitle>
      </BarItemButton>
    </LinkWrapper>
  );
}

function LinkWrapper({ children, href }: { children: JSX.Element; href?: string }) {
  if (href) {
    return <Link href={href} passHref children={children} />;
  }
  return children;
}

const BarItemButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 7.5px 0 6px;
  position: relative;
`;

interface BarItemTitleProps {
  color?: string;
}

const BarItemTitle = styled.div<BarItemTitleProps>`
  color: ${({ color }) => color};
  text-align: center;
`;

function colorStatus(selected: boolean) {
  if (selected) {
    return Gray_Light_900;
  } else {
    return Gray_Light_400;
  }
}
