import { useRouter } from 'next/router';

import { BottomBarItem } from '../component/BottomBarItem';
import { BottomTabBar } from '../component/BottomTabBar';
import { FixedWrapper } from '../component/Fixed';
import { useGNBConfig } from '../context/ConfigProvider';
import { createNavigationFromPath, useGNBUbl } from '../log/useGNBUbl';
import { useBottomTabBarLoginService } from '../service/useBottomTabBarLoginService';
import { useBottomTabBarService } from '../service/useBottomTabBarService';

/** @public */
export const GlobalNavigationContainer = () => {
  const { asPath, query } = useRouter();
  const { path } = useGNBConfig();
  const { home, categories, my_page, picks } = useBottomTabBarService();
  const { click } = useGNBUbl();
  const { goToLogin } = useBottomTabBarLoginService();
  const ublNavigation = createNavigationFromPath({ asPath, path, query });
  return (
    <FixedWrapper>
      <BottomTabBar>
        <BottomBarItem
          title={home.title}
          ActvieIcon={home.ActvieIcon}
          DeactiveIcon={home.DeactiveIcon}
          selected={home.selected}
          href={home.href}
          onClick={() => {
            click({ object_id: 'home', navigation: ublNavigation });
          }}
        />
        <BottomBarItem
          title={categories.title}
          ActvieIcon={categories.ActvieIcon}
          DeactiveIcon={categories.DeactiveIcon}
          selected={categories.selected}
          href={categories.href}
          onClick={() => {
            click({ object_id: 'category', navigation: ublNavigation });
          }}
        />
        <BottomBarItem
          title={picks.title}
          ActvieIcon={picks.ActvieIcon}
          DeactiveIcon={picks.DeactiveIcon}
          selected={picks.selected}
          href={picks.href}
          onClick={(event) => {
            click({ object_id: 'saved_product', navigation: ublNavigation });
            goToLogin(event, picks.href);
          }}
        />
        <BottomBarItem
          title={my_page.title}
          ActvieIcon={my_page.ActvieIcon}
          DeactiveIcon={my_page.DeactiveIcon}
          selected={my_page.selected}
          href={my_page.href}
          onClick={(event) => {
            click({ object_id: 'my_page', navigation: ublNavigation });
            goToLogin(event, my_page.href);
          }}
        />
      </BottomTabBar>
    </FixedWrapper>
  );
};
