import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconHeartSolid32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={2}
        d='M15.157 9.332 16 10.653l.843-1.32C17.749 7.912 19.281 7 21 7c2.723 0 5 2.312 5 5.25 0 .627-.164 1.29-.347 1.826a8.075 8.075 0 0 1-.323.798l-.02.04-.002.005v.001l-.001.001-.012.023-.01.025c-1.02 2.285-3.424 4.82-5.688 6.845A54.762 54.762 0 0 1 16 24.76a54.762 54.762 0 0 1-3.597-2.946c-2.264-2.026-4.668-4.56-5.687-6.845l-.011-.025-.012-.023v-.001l-.004-.007-.02-.04a8.064 8.064 0 0 1-.323-.798c-.182-.535-.346-1.198-.346-1.824C6 9.31 8.277 7 11 7c1.72 0 3.251.913 4.157 2.332Z'
      />
    </Svg>
  );
};
export default SvgIconHeartSolid32;
