import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconMypageRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={1.5} d='M16 6.75A3.75 3.75 0 1 0 16 14.25 3.75 3.75 0 1 0 16 6.75z' />
      <path
        stroke='currentColor'
        strokeLinecap='square'
        strokeWidth={1.5}
        d='m24.983 25.25-2.32-7.5H9.462l-2.43 7.5h17.95Z'
      />
    </Svg>
  );
};
export default SvgIconMypageRegular32;
