import { ReactNode, forwardRef, Children } from 'react';
import styled from '@emotion/styled';
import { Gray_Light_White, web_max_width } from '@common/styles';
import { Gray_Light_200 } from '@croquiscom/zds-core';

/** @public */
export interface BottomTabBarProps {
  children: ReactNode;
}

/** @public */
export const BottomTabBar = forwardRef<HTMLDivElement, BottomTabBarProps>((props, ref) => {
  return (
    <WebContainer ref={ref}>
      <Curatin />
      {Children.map(props.children, (child, index) => (
        <Item>{child}</Item>
      ))}
    </WebContainer>
  );
});

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 16px 16px 0px 0px;
  background: ${Gray_Light_White};
  border-top: 0.5px solid ${Gray_Light_200};
  border-left: 0.5px solid ${Gray_Light_200};
  border-right: 0.5px solid ${Gray_Light_200};
  position: relative;
`;

const Curatin = styled.div`
  position: absolute;
  pointer-events: none;
  width: 101%;
  height: 50%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

const WebContainer = styled(Container)`
  max-width: ${web_max_width}px;
  margin: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
`;
