import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconHomeRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={2} d='M12.5 11h6l-5 10h6' />
      <path
        stroke='currentColor'
        strokeLinecap='square'
        strokeWidth={1.5}
        d='M9 5.75h14A3.25 3.25 0 0 1 26.25 9v14A3.25 3.25 0 0 1 23 26.25H9A3.25 3.25 0 0 1 5.75 23V9A3.25 3.25 0 0 1 9 5.75Z'
      />
    </Svg>
  );
};
export default SvgIconHomeRegular32;
