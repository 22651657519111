import { useTracker } from '@common/log-manager';

import { HomePath } from '../model/home-path';
import { convertToPageIdFromQuery, PageId, PageIdEnum } from '../model/page-info';

import { ParsedUrlQuery } from 'querystring';

type GNBObjectID = 'home' | 'category' | 'my_page' | 'saved_product';
type GNBNavigation =
  | 'home_ad'
  | 'home_brand'
  | 'home_beauty'
  | 'global_navigation_page'
  | 'category_landing'
  | 'saved_product'
  | 'my_page'
  | 'login';
export function useGNBUbl() {
  const tracker = useTracker();

  const click = ({ navigation, object_id }: { object_id: GNBObjectID; navigation: GNBNavigation }) => {
    console.log({ navigation, object_id });
    tracker.sendImmediately({
      category: 'click',
      navigation,
      object_section: 'global_navigation_bar',
      object_type: 'button',
      object_id,
    });
  };
  return {
    click,
  };
}

function createNavigationFromPageId(page_id?: PageId): GNBNavigation {
  switch (page_id) {
    case PageIdEnum.web_brand:
      return 'home_brand';
    case PageIdEnum.web_beauty_best:
      return 'home_beauty';
    case PageIdEnum.web_home:
    default:
      return 'home_ad';
  }
}

export function createNavigationFromPath({
  asPath,
  path,
  query,
}: {
  asPath: string;
  path: HomePath;
  query: ParsedUrlQuery;
}): GNBNavigation {
  if (asPath === path.root || asPath.startsWith(path.home)) {
    return createNavigationFromPageId(convertToPageIdFromQuery(query));
  }
  if (asPath.startsWith(path.categories.root) && typeof query['category_id'] !== 'string') {
    return 'global_navigation_page';
  }
  if (asPath.startsWith(path.categories.root) && typeof query['category_id'] === 'string') {
    return 'category_landing';
  }
  if (
    asPath.startsWith(path.my_page.root) ||
    asPath.startsWith(path.auth.root) ||
    asPath.startsWith(path.my_review.list) ||
    asPath.startsWith(path.my_review.detail) ||
    asPath.startsWith(path.my_review.completed)
  ) {
    return 'my_page';
  }
  if (asPath.startsWith(path.picks.root)) {
    return 'saved_product';
  }
  if (asPath.startsWith('/login')) {
    return 'login';
  }
  if (process.env.NODE_ENV === 'development') {
    throw new Error('유효하지 않은 경로입니다.');
  }
  return 'home_ad';
}
