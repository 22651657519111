import { isKakaotalk } from '@common/device-manager';
import { loginKakao } from '@common/utils';
import { getUserAccount } from '../graphql/user-account.2';
import { MouseEvent } from 'react';

export function useBottomTabBarLoginService() {
  const isLogin = async () => {
    try {
      const {
        data: { user_account },
      } = await getUserAccount();
      return Boolean(user_account);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const goToLogin = async (event: MouseEvent<HTMLAnchorElement>, redirect_url: string) => {
    const is_login = await isLogin();
    const is_kakaotalk = isKakaotalk();

    if (!is_login && is_kakaotalk) {
      event.preventDefault();
      loginKakao(redirect_url);
      return false;
    }
  };

  return { goToLogin };
}
